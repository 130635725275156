import service from '@/until/api'
//分页
export function _TableDate(data) {
    return service({
        url: '/report-data/carOperationLog/list/' + JSON.parse(sessionStorage.getItem("quisize")) + "/" + JSON.parse(sessionStorage.getItem("quipage")),
        method: 'POST',
        data
    })
}


export function _Download(data) {
    return service({
        url: '/report-data/carOperationLog/export',
        method: 'POST',
        data,
        responseType: 'blob'
    })
}