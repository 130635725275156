<template>
  <div>
    <div class="headSearch">
      <div>
        <button class="centerBtn mb20" @click="getDowload">导出车辆</button>
      </div>

      <el-form :inline="true">
        <el-form-item>
          <el-input
            v-model="list.carNumber"
            placeholder="请输入车辆编号"
            class="centerInput"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="list.userName"
            placeholder="请输入账户名"
            class="centerInput"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-date-picker
            v-model="list.date"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy-MM-dd"
            @change="getTableDate"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <button
            class="centerCzbtn ml20"
            type="button"
            @click="getTableDate(searchPage)"
          >
            搜索
          </button>
        </el-form-item>
      </el-form>

      <Table
        :data1="tableData"
        :columns1="columns"
        :total="total"
        @getAjax="getTableDate"
        :loading="loading"
        :page="page.currentPage"
        :size="page.pageSize"
      />
    </div>
  </div>
</template>

<script>
import Table from '@/components/Table.vue'
import { _TableDate, _Download } from '@/api/equipmentMonitoring'
export default {
  components: {
    Table
  },
  data() {
    return {
      loading: false,
      total: 0,
      tableData: [],
      columns: [
        {
          label: '账号名',
          prop: 'userName'
        },
        {
          label: '所属企业',
          prop: 'companyName'
        },
        {
          label: '时间',
          prop: 'controlTime'
        },
        {
          label: '车辆编号',
          prop: 'carNumber'
        },
        {
          label: '车辆VIN',
          prop: 'carVin'
        },
        {
          label: '车辆所属园区',
          prop: 'parkName'
        },
        {
          label: '操作',
          prop: 'controlCommand'
        },
        {
          label: '执行结果',
          prop: 'controlResult'
        },
        {
          label: '操作来源',
          prop: 'controlSource'
        }
      ],
      list: {
        carNumber: '',
        userName: '',
        date: [],
        startDate: '',
        endDate: ''
      },
      page: {
        currentPage: 1,
        pageSize: 10
      },
      searchPage: {
        limit: 10,
        page: 1
      }
    }
  },
  mounted() {
    //存储当前页数和页面数据条数
    this.getTableDate()
  },
  methods: {
    //获取分页数据
    async getTableDate(pagination) {
      this.loading = true
      if (pagination) {
        if (pagination.page) {
          this.page.currentPage = pagination.page
          this.page.pageSize = pagination.limit
        }
      }
      window.sessionStorage.setItem('quipage', this.page.currentPage)
      window.sessionStorage.setItem('quisize', this.page.pageSize)
      //选择时间，存储分页
      if (this.list.date == null) {
        this.list.date = []
        this.list.startDate = ''
        this.list.endDate = ''
      }
      if (this.list.date.length == 2) {
        this.list.startDate = this.list.date[0]
        this.list.endDate = this.list.date[1]
      }
      _TableDate(this.list).then(res => {
        if (res.success == true) {
          this.tableData = res.data.list
          this.total = res.data.total
        } else {
        }
        this.loading = false
      })
    },
    //导出数据
    getDowload() {
      _Download(this.list).then(response => {
        const url = window.URL.createObjectURL(
          new Blob([response], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
          })
        )
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        this.exportLoad = false

        link.download = '车辆控制记录.xlsx'
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
        // this.timer = setTimeout(() => {
        //   this.$message.success("导出成功");
        // }, 3000);
      })
    }
  }
}
</script>

<style scoped>
/deep/.el-input__inner {
  border: 0px;
}
</style>
